@import '../../../../styles/application-specific/variables';
@import '../../../../styles/bootstrap-override/variables';

.status-screen {
  align-items: center;
  background: white;
  border: solid 1px $card-border-color;
  display: flex;
  flex-direction: column;
  height: 650px;
  margin-bottom: 20px;
  padding-top: 40px;
  text-align: center;

  .status-message {
    color: $section-text-color;
    font-size: 14px;
    margin-top: 20px;
    width: 460px;
  }

  .status-title {
    color: $primary;
    font-size: 16px;
    font-weight: 600;
    margin-top: 40px;
  }
}