// Used for expanding the  width of the game play
// message when there are less than 5 results.
.w-100 {
  width: 100%;
}
.w-200 {
  width: 200%;
}
.w-300 {
  width: 300%;
}
.w-400 {
  width: 400%;
}

// Sticking with Bootstrap standard of .02px offset between
// @media query breakpoints, we'll use this to ensure that
// we don't have any overlap between moving from "small" to
// "normal" mode. For example, without defining this, we'd
// end up in a situation where the UI would be incorrectly
// showing neither "normal" or "small" mode elements on
// pixel widths exactly equal to $small-mode-breakpoint.
// More information/examples can be found here:
// https://getbootstrap.com/docs/4.1/layout/overview/#responsive-breakpoints
$bootstrap-breakpoint-offset: .02px;

// Use for hiding elements when we're in "small" mode.
// Since the breakpoint is an exclusive (not inclusive)
// selection, we want it to hide where the max-width is
// 1px less and below our breakpoint. For example, if
// we have a breakpoint of 832px, that means "normal"
// mode is >= 832px and "small" mode is < 832px.
@media (min-width: 0px) and (max-width: $small-mode-breakpoint - $bootstrap-breakpoint-offset) {
  .hide-in-small-mode {
    display: none !important;
  }
}
@media (min-width: $small-mode-breakpoint) {
  .hide-in-normal-mode {
    display: none !important;
  }
}