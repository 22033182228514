@import '../../../styles/application-specific/variables';
@import '../../../styles/bootstrap-override/variables';

.associated-account-table {
  border: solid 0.8px $card-border-color !important;
  overflow-x: hidden !important;

  & td {
    border-top: 0 !important;
    max-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  & th {
    border-bottom: 1px solid $card-border-color !important;
    font-weight: bold !important;
  }
  
  tr:hover, .active {
    background-color: $table-selection-color;
    cursor: pointer;
  }

  tr:hover {
    color: $primary;
  }
}

/* width */
::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ffffff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c3c3c3;
  border-radius: 20px;
}