.account-device-details-placeholder {

  align-items: center;
  display: flex;
  flex: 1 1 auto;
  justify-content: center;
  margin-bottom: 3rem;
  margin-top: 3rem;
  text-align: center;

  .text {
    line-height: 1.25;
    margin-left: 20px;
    text-align: center;
    width: 225px;
  }
}