@import '../styles/bootstrap-override/variables';

.header-search {

  &__search-text-box {
    border-radius: .2rem 0 0 .2rem;
    width: 26rem;

    &::placeholder {
      font-size: .85rem;
      font-style: italic;
    }
  }

  &__search-button {
    background: $primary !important;
    border-radius: 0 .2rem .2rem 0;
    height: calc(1.5em + 0.5rem + 2px);

    svg {
      height: 20px;
      width: 20px;
    }
  }

  &__validation-message {
    width: auto;
  }
}