.search-field {
  border-bottom: 1px solid #CCCCCC;
  flex-grow: 1;
  height: 24px;
  margin-left: auto;
  max-width: 250px;

  &__input {
    background: url("../../../../assets/search_table_icon.svg") no-repeat 4px 4px;
    background-size: 14px;
    border: 0;
    border-radius: 2px;
    width: 100%;

    &:focus, &:focus-visible {
      outline: 0;
      border-color: #80bdff;
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, .25)
    }
  }
}