// The breapoint at which we change from
// our "normal" mode to our "small" mode.
$small-mode-breakpoint: 832px;

// Bootstrap documentation on breakpoints:
// https://getbootstrap.com/docs/4.0/layout/grid/#grid-tiers
$grid-breakpoints: (
  xs: 0,
  sm: $small-mode-breakpoint,
  md: 925px,
  lg: 1000px,
  xl: 1280px
);
$container-max-widths: (
  sm: $small-mode-breakpoint,
  md: 925px,
  lg: 1000px,
  xl: 1220px
);