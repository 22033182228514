@import '../../../styles/application-specific/variables';
@import '../../../styles/bootstrap-override/variables';

.collapsible-pane-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  min-height: 40px;
}

.collapsible-pane-heading-container {
  gap: 0.5rem;
}

.dice-accordion-collapse {
  color: $section-text-color;
  border: solid 1px $card-border-color;
  border-top: 0;
}