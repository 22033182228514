@import '../../../styles/application-specific/variables';
@import '../../../styles/bootstrap-override/variables';

.orders-table {
  &__filter {
    display: flex;
    padding: .75rem;
  }

  &__filter-label {
    font-size: 12px;
    display: contents;
  }
}