@import '../../../styles/bootstrap-override/variables';

.game-card {
  display: flex;
  flex-direction: column;
  padding: .75rem;
  width: 20%;

  &:not(:last-child) {
    border-right: 1px solid $card-border-color;
  }

  &__image {
    margin-bottom: .75rem;
    align-self: center;
    width: 95px;

    img {
     width: 100%;
    }
  }

  &__text {
    text-align: center;
    height: 100%;
  }

  &__title {
    margin-bottom: 1.25rem;
  }
}