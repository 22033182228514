.card {
  border: 0;
  border-radius: 0;
  margin-bottom: 2em;

  .card-title {
    font-size: $font-size-base;
    font-weight: normal;
  }

  .card-header {
    background-color: white;
    border: 1px solid $card-border-color !important;
    color: $primary;
    padding: .25rem .75rem;
  }
}

.card-body {
  padding: .7em;
}