.outline-message {

  align-items: center;
  display: flex;
  flex: 1 1 auto;
  justify-content: center;
  margin-bottom: 3rem;
  margin-top: 3rem;

  &.message-bottom {
    flex-direction: column;
  }

  &.message-right {
    flex-direction: row;
  }

  .message {

    font-size: 12px;
    line-height: 15px;
    max-width: 225px;

    &.message-bottom {
      margin-top: 20px;
      text-align: center;
    }

    &.message-right {
      margin-left: 20px;
      text-align: left;
    }
  }
}