.eshop-order-overlay {

    .popover {
        min-width: 300px;
    }

    .popover-header {
        background-color: unset;
        border-bottom: unset;
    }

    .copy-button__icon {
        color: #A14DA0; 
    }

    &__header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    &__grid {
        display: grid;
        grid-template-columns: 1fr 2fr;
        gap: 5px 15px;
    }

    &__info-row {
        display: flex;
        align-items: center;
        margin-bottom: 5px;
    }

    &__label {
        text-align: right;
    }

    &__info {
        display: flex;
        align-items: center;
        line-height: 22px;
        text-align: left;
    }

    &__copy-icon {
        margin-left: 10px;
    }

    &__arrow-icon {
        height: 13px;
        width: 13px;
        fill: #484848;
        margin-left: 5px;
    }


    &__close-icon {
        display: block;
        height: 20px;
        width: 15px;
        fill: #008080;
    }
}