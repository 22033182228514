.game-play-data {
  &__close-button {
    color: #008080;
    font-size: 32px;
    font-weight: bold;
    height: 40px;
    line-height: 1rem;
    position: absolute;
    right: 0;
    top: 0;
    width: 40px;
  }
}