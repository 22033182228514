@import '../../../styles/bootstrap-override/variables';

.game-recommendations {

  .game-recommendation-entry {
    padding: .75rem;

    // For sm screens and under (what we use as our min-width),
    // render a border on the right for each recommendation (except the last one).
    @media (max-width: map_get($grid-breakpoints, "sm")) {
      &:not(:last-child) {
        border-right: .5px solid $card-border-color;
      }
    }

    // For widths over sm screens, render a border on the bottom
    // for each recommendation (except the last one).
    @media (min-width: map_get($grid-breakpoints, "sm")) {
      &:not(:last-child) {
        border-bottom: .5px solid $card-border-color;
      }
    }
  
    .image {
      align-self: center;

      img {
        margin-right: .5rem;
        width: 70px;
      }
    }

    .title {
      align-self: center;
    }
  }
}