// Define our minimum application width.
html, body {
  background: $gray-100;
  font-family: 'PT Sans', sans-serif;
  min-width: $html-body-min-width;
  // added for local testing of font weights (on Mac)
  -webkit-font-smoothing: antialiased;
}

// Ensure that we're always able to style
// to 100% of the height of our current viewport.
// Without ensuring that these top-level parent
// elements are using all the space they can,
// there's no way to make any of their children
// use all they can either.
html, body, #root {
  height: 100%;
}

// This is the <footer> element generated
// from Nintendo's Hyrule React component.
// This is outside of our control, so we'll
// be styling it ourselves here.
footer {
  text-align: center;
}

h1, h2, h3, h4, h5, h6 {
  color: $primary;
  font-weight: 600;
}

h6, .table thead tr th {
  font-family: 'Open Sans', sans-serif;
}

a, .link {
  color: $primary;
  cursor: pointer;
  text-decoration: underline;
  border-radius: 2px;

  &:hover {
    color: $hyperlink-hover-color;
  }
}

:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, .25);
}