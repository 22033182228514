@import '../../../../styles/application-specific/variables';
@import '../../../../styles/bootstrap-override/variables';

.radio-pill {

  &__radio {
    left: -9999px;
    opacity: 0;
    position: absolute;
  }

  &__pill {
    display: inline-block;
    border: 1px solid $secondary;
    border-radius: 16px;
    color: $secondary;
    padding-left: .75rem;
    padding-right: .75rem;
    line-height: 22px;
  }

  &__radio:not(:disabled):hover ~ &__pill {
    cursor: pointer;
    box-shadow: $hover-focus-box-shadow;
    background-color: #874186;
    border-color: #7f3d7e;
    color: #FFFFFF;
  }

  &__radio:disabled ~ &__pill {
    cursor: not-allowed;
    opacity: 65%;
  }

  &__radio:focus-visible ~ &__pill {
    box-shadow: $hover-focus-box-shadow;
  }

  &__radio:checked ~ &__pill {
    background-color: $secondary;
    color: #FFFFFF;
  }
}