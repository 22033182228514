// Override Bootstrap's primary color and notify its $theme-colors variable.
$primary: #008080;
$secondary: #A14DA0;
$theme-colors: (
        "primary": $primary,
        "secondary": $secondary
);

// Used for all application borders.
$card-border-color: #ccc;

$text-muted: #6b747c;