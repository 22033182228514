.repairs-table-popover-trigger {
    font-size: 14px;
    line-height: 19px;
    text-decoration-line: underline;
    color: #008080;
    padding:0;
    margin: .5rem;

    &:hover {
        color: #008080;
    }
}