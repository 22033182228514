@import '../../../styles/application-specific/variables';
@import '../../../styles/bootstrap-override/variables';

.dice-table {
  border: 0;
  color: $section-text-color !important;
  text-align: left;

  tbody tr:last-child td {
    border-bottom: 0;
  }

  tr:nth-child(even) {
    background-color: $table-background-color;
  }

  td {
    max-width: 150px;
    vertical-align: middle;

    &:first-child {
      border-left: 0;
    }

    &:last-child {
      border-right: 0;
    }
  }

  thead th {

    &:first-child {
      border-left: 0;
    }

    &:last-child {
      border-right: 0;
    }
  }
}
