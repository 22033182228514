@import '../../../styles/bootstrap-override/variables';

.game-play-history-detail-popover {
  width: 170px;

  .header {
    align-items: flex-start;
    background-color: white;
    border-bottom: 0;
    color: $primary;
    display: flex;
    font-weight: 600;
    justify-content: space-between;

    .close-button {
      align-self: flex-start;
      color: inherit;
      cursor: pointer;
      font-size: 1.5rem;
      font-weight: inherit;
      line-height: 1rem;
    }
  }
}