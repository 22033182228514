@import '../../../styles/bootstrap-override/variables';

.membership-table {
  background-color: white !important;
  color: $primary !important;
  font-size: 1em !important;
  font-weight: 600 !important;
  text-align: center !important;
}

.membership-headers th {
  font-weight: bold !important;
}