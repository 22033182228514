.tracking-info-overlay {

    .popover {
        min-width: 350px;
    }

    .popover-header {
        background-color: unset;
        border-bottom: unset;
    }

    .copy-button__icon {
        color: #A14DA0; 
    }

    &__header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    &__grid {
        display: grid;
        grid-template-columns: 43% 57%;
        gap: 5px 5px;
    }

    &__row {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    &__label {
        font-weight: 700;
        font-size: 12px;
        line-height: 22px;
        text-align: right;
    }

    &__info {
        align-items: center;
        color: #008080;
        line-height: 22px;
        text-align: left;
    }

    &__info-unavailable {
        align-items: center;
        color: #484848;
        line-height: 22px;
        text-align: left;
    }

    &__copy-icon {
        margin-left: 5px;
    }

    &__arrow-icon {
        height: 13px;
        width: 13px;
        fill: #484848;
        margin-left: 5px;
    }

    &__close-icon {
        display: block;
        height: 20px;
        width: 15px;
        fill: #008080;
    }
}