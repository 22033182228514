@import '../../../../styles/application-specific/variables';
@import '../../../../styles/bootstrap-override/variables';

.no-access-component {

  margin-bottom: 1rem;

  .page-title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 35px;
    margin-top: 15px;
  }

  .col {
    padding-right: 20px;
    padding-left: 20px;
  }

  .card {
    margin-bottom: 0;
    max-width: 455px;
  }

  .line {
    border-right: 1px solid $card-border-color;
  }

  .no-access {
    border: solid 1px $card-border-color;
    height: 197px;

    .btn {
      border-radius: 2px;
      height: 47px;
      margin-left: auto;
      margin-right: auto;
      width: 200px;
    }

    .card-body {
      padding: 20px 70px 20px 70px;
    }

    p {
      font-size: 12px;
      margin-bottom: 0;
    }

    .card-title span {
      font-size: 14px;
      font-weight: bold;
      line-height: 18px;
      color: $section-text-color;
    }
  }
}