.header-component {
  margin-top: 20px;

  & .row {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }

  .warpzone-logo-image {
    height: 75px;
    width: 75px;
  }
}