.order-icon {
  align-items: center;
  display: flex;
  height: 1.5em;
  justify-content: center;
  width: 1.5em;

  &__icon {
    height: 1.5em;
    width: auto;
  }
}