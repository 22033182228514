.table {
  margin-bottom: 0;

  & td {
    padding-left: .75em;
  }

  & thead tr th {
    background-color: $table-header-background-color;
    border-bottom: 0;
    color: $table-header-font-color;
    font-size: .85em;
    font-weight: 600;
    padding: .5em .75em; 
  }
}