@import '../../../../styles/application-specific/variables';
@import '../../../../styles/bootstrap-override/variables';

.wz-pagination {
  display: flex;
  flex-flow: row;
  padding: 0.75rem;
  border-top: solid 1px #ccc;

  &__page-size-select {
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
  }

  &__page-button {
    &:hover:enabled, &:focus {
      box-shadow: $hover-focus-box-shadow;
      background-color: #874186;
      border-color: #7f3d7e;
    }

    &:disabled {
      cursor: not-allowed;
    }
  }

  &__page-size-label {
    margin-right: 10px;
    margin-bottom: 0;
  }
}